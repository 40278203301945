import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Box, Typography, Button, Paper, FormGroup, FormControlLabel, Checkbox, TextField
} from '@mui/material';
import router from "../../../../routePaths/routePaths";

export default function HomeView(prop) {
    let navigate = useNavigate()

    let image = (prop.homeRecord.featured !== undefined) ?  JSON.parse(prop.homeRecord.featured[0].pro_image) : '';
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Paper 
                    elevation={0} sx={{cursor: 'pointer', overflow: 'hidden'}} 
                    square
                    onClick={()=>navigate(router.product+"/"+prop.homeRecord.featured[0].id)}>
                    <img src={[image[0]]} width='100%' className='zoom p-0' alt="laoding"/>
                </Paper>
                <div className="py-5" style={{textAlign: '-webkit-center'}}>
                    <Paper 
                        elevation={0}
                        sx={{cursor: 'pointer', height: '30vh', overflow: 'hidden', width:'90%'}} 
                        square
                        onClick={()=>navigate(router.product+"/"+prop.homeRecord.featured_week[0].id)}>
                        {prop.homeRecord.featured_week !== undefined &&
                            <img src={JSON.parse(prop.homeRecord.featured_week[0].pro_image)[0]} width='100%' alt="laoding" className='zoom p-0'/>
                        }
                    </Paper>
                </div>
                <div className="row pt-5 bg-gray justify-content-center">
                    <div className="width-95">
                        <h4 className="font-bold black-outline mb-5">SHOP BY BRAND</h4>
                        <div className="row">
                            {prop.homeRecord.brands !== undefined && prop.homeRecord.brands.map((x,index)=>{
                                return(
                                    <div className="col-md-2 col-6 my-2">
                                        <Paper 
                                            elevation={0} 
                                            sx={{cursor: 'pointer'}}
                                            onClick={()=>navigate(router.collections+"/brand="+x.id)}>
                                            <img src={x.brand_image} width='100%' height="160px" className='zoom p-0' alt={index}/>
                                        </Paper>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="btn-div mt-5">
                            <Button type='button' className="btn-black btn-centered" onClick={()=>navigate(router.brands)}>
                                SHOP ALL BRANDS
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row pt-5 justify-content-center">
                    <div className="width-95">
                        <h4 className="font-bold black-outline">FEATURED THIS WEEK</h4>
                        <div className="row">
                            {prop.homeRecord.featured_week !== undefined && prop.homeRecord.featured_week.map((x,index)=>{
                                return(
                                    <div className="col-md-4 my-4">
                                        <Paper variant="outlined" sx={{cursor: 'pointer', p: 1}} square>
                                            <div className="row text-center">
                                                <Paper 
                                                    elevation={0}
                                                    sx={{cursor: 'pointer', height: '400px', overflow: 'hidden', p: 0}} 
                                                    square
                                                    onClick={()=>navigate(router.product+"/"+x.id)}>
                                                    <img src={JSON.parse(x.pro_image)[0]} width='100%' height="400px" className="zoom p-0" alt={index}/>
                                                </Paper>
                                                <Typography variant="h5" component="h2" className="font-bold text-nowrap my-2">
                                                    {x.pro_name}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2" className="text-nowrap">
                                                    {x.pro_price}
                                                </Typography>
                                                <Button type='button' className="btn-black my-3" onClick={()=>navigate(router.product+"/"+x.id)}>
                                                    SHOP NOW
                                                </Button>
                                            </div>
                                        </Paper>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="row pt-5 justify-content-center">
                    <div className="width-95">
                        <h4 className="font-bold black-outline">FIT ON ROTATION</h4>
                        <div className="row">
                            {prop.homeRecord.fit_rotation !== undefined && prop.homeRecord.fit_rotation.map((x,index)=>{
                                return(
                                    <div className="col-6 col-md-4 my-4">
                                        <Paper elevation={0} sx={{cursor: 'pointer'}} onClick={()=>navigate(router.product+"/"+x.id)}>
                                            <img src={JSON.parse(x.pro_image)[0]} width='100%' height={'200px'} className='zoom p-0' alt={index}/>
                                        </Paper>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="py-5" style={{textAlign: '-webkit-center'}}>
                    <Paper 
                        elevation={0} 
                        sx={{cursor: 'pointer', overflow: 'hidden', width:'90%'}} 
                        square
                        onClick={()=>navigate(router.product+"/"+prop.homeRecord.featured_week[0].id)}>
                        {prop.homeRecord.featured_week !== undefined &&
                            <img src={JSON.parse(prop.homeRecord.featured_week[0].pro_image)[0]}  width='100%' alt="laoding" className='zoom p-0'/>
                        }
                    </Paper>
                </div>
                <div className="bg-gray">
                    <div className="container text-center pt-5"> 
                        <h6 className="font-bold">SIGN UP TO OUR VIP LIST TO GET ALL OUR EXCLUSIVE OFFERS BEFORE ANYONE ELSE!</h6>
                        <h6 className="">Subscribe to get early access to drops, exclusive offers and promotions.</h6>
                        <h6 className="">Choose Your Product Preferences:</h6>
                        <form onSubmit={prop.handleSubmit}>
                            <Box sx={{display: 'flex', flexDirection: 'row', placeContent: 'center'}}>
                                <FormGroup sx={{flexDirection: 'row'}}>
                                    <FormControlLabel 
                                        control={<Checkbox 
                                            name="men"
                                            checked={prop.inputField['men']}
                                            onChange={prop.handleChange}
                                        />} 
                                        label="Men" />
                                    <FormControlLabel 
                                        control={<Checkbox 
                                            name="women"
                                            checked={prop.inputField['women']}
                                            onChange={prop.handleChange}
                                        />}
                                        label="Women" />
                                    <FormControlLabel 
                                        control={<Checkbox 
                                            name="kids"
                                            checked={prop.inputField['kids']}
                                            onChange={prop.handleChange}
                                        />}
                                        label="Kids" />
                                </FormGroup>
                            </Box>
                            <Box sx={{display: 'flex', placeContent: 'center', paddingBottom: 5}}>
                                <div className="subscribe-div">
                                    <FormGroup sx={{flexDirection: 'row', placeContent: 'center', width: '100%'}}>
                                        <div className="col-8">
                                            <TextField 
                                                id="outlined-basic" 
                                                label="Enter your email" 
                                                variant="outlined" 
                                                className="textField"
                                                name="email"
                                                type="email"
                                                value={prop.inputField['email']}
                                                onChange={prop.handleChange}
                                                sx={{width: '100%'}}
                                                required
                                            />
                                        </div>
                                        <div className='col-4 p-0'>
                                            <Button type='submit' className='btn-black' sx={{p: 0, padding: '9px 12px',width: '95%'}}>SIGN UP</Button>
                                        </div>
                                    </FormGroup>
                                </div>
                            </Box>
                        </form>
                    </div>
                </div>
            </Box>
        </>
    );
}
